.container{
    display: grid;
    grid-template-columns: 1fr 2fr;
  
}


#leftBar{
    display: grid;
    justify-items: center;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows:
        minmax(auto, 0.1fr)
        42px
        minmax(auto, 0.1fr)
        42px
        minmax(auto, 0.5fr)
        42px
        minmax(auto, 0.2fr);


    height: 100vh;
    background-color: #ffffff;
    color: #000000;
}

body{
    margin: 0px;
    padding: 0px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

#content{
    display: grid;
    grid-template-rows: repeat(3,minmax(auto,0.2fr));
    justify-items: center;
}

.currentSelected{
    margin-top: 10px;
    cursor: pointer;
    pointer-events: none;
    width: fit-content;
    color: #EC53B0;
}

.button{
    margin-top: 10px;
    font-size: larger;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
}
.button:hover{
    color: #EC53B0;
    
}

.disabled{
    cursor: default;
    pointer-events: none;
}
button{
    width: 100px;
    height: 50px;
    background-color: #EC53B0;
    color: white;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    /* margin: 50px; */
}
button:hover{
     background-color: #e9329f;
}

.divider {
    width: 80%; /* Adjust the width as needed */
    margin-left: 10%; /* Half of the gap you want on the left side */
    margin-right: 10%; /* Half of the gap you want on the right side */
    height: 2px;
    background-color:whitesmoke;
    margin-top: 20px;
    margin-bottom: 20px;
}

#sortButton{
    display: none;
}


@media (max-width: 499px) {
    h1 {
        font-size: 14px;
    }

    h2{
        font-size: 12px;
    }

    .button{
        font-size: 10px
    }
}