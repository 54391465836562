

.leftContainer {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
  }
.arrayContainer{
    display: grid;
    width: 70%;
    grid-template-rows: 1fr;
    /* grid-template-columns: repeat(85,1fr); */
    justify-items: center;
    align-items: center;
    gap: 1px;
}

.arrayBar{
    width: 100%;
    margin: 0 1px;
    background-color: #EC53B0;
    align-self: end;
}